<template>
    <div class="loginout">
        <n-button quaternary class="mr-10" @click="exit">退出</n-button>
    </div>
    <div class="home-container">
        <n-card title="主页" style="margin-bottom: 16px">
            <n-tabs type="line" animated >
                <n-tab-pane name="oasis" tab="添加用户信息">
                    <div class="userInfo">
                        <div>
                            <n-image width="150" height="150" :src="user.file" />
                        </div>
                        <div>
                            <n-upload :custom-request="upload">
                                <n-button>点我上传头像</n-button>
                            </n-upload>
                        </div>
                        <div>
                            <n-card hoverable>
                                <div class="nickname-container mt-20">
                                    <span>昵称：</span>
                                    <n-input v-model:value="user.nickName" type="text" show-password-on="mousedown"
                                        placeholder="请输入昵称" :maxlength="50" :style="{ width: '50%' }" />
                                </div>
                                <div class="tel-container mt-20">
                                    <span>电话：</span>
                                    <n-input v-model:value="user.tel" type="text" show-password-on="mousedown"
                                        placeholder="请输入手机号" :maxlength="50" :style="{ width: '50%' }" />
                                </div>
                                <div class="email-container mt-20">
                                    <span>邮箱：</span>
                                    <n-input v-model:value="user.email" type="text" show-password-on="mousedown"
                                        placeholder="请输入邮箱" :maxlength="50" :style="{ width: '50%' }" />
                                </div>
                                <div class="desc-container mt-20">
                                    <span>描述：</span>
                                    <n-input v-model:value="user.desc" type="textarea" show-password-on="mousedown"
                                        placeholder="用一句或一段话简单描述自己..." :maxlength="150" :style="{ width: '50%' }" />
                                </div>
                                <div class="button">
                                    <n-button tertiary type="primary" @click="inputUserInfo">添加用户信息</n-button>
                                </div>
                            </n-card>
                        </div>
                    </div>
                </n-tab-pane>
                <n-tab-pane name="userList" tab="用户信息列表">
                    <n-data-table :columns="columns" :data="createData" :pagination="pagination" />
                    <!-- <pre>{{ JSON.stringify(createData, null, 2) }}</pre> -->
                </n-tab-pane>
                <n-tab-pane name="the beatles" tab="重置账户密码">
                    <n-card hoverable>
                        <div class="password-container">
                            <span>请输入新密码：</span>
                            <n-input v-model:value="user.password" type="password" show-password-on="mousedown"
                                placeholder="请输入新的密码" :maxlength="8" :style="{ width: '50%' }" />
                        </div>
                        <div class="password-container">
                            <span>再次确认密码：</span>
                            <n-input v-model:value="user.rePassword" type="password" show-password-on="mousedown"
                                placeholder="再次请输入新密码" :maxlength="8" :style="{ width: '50%' }" />
                        </div>
                        <div class="button">
                            <n-button tertiary type="primary" @click="changePassword">确认修改密码</n-button>
                        </div>
                    </n-card>
                </n-tab-pane>
                <n-tab-pane name="jay chou" tab="注销账号">
                    <p>温馨提示：</p>
                    <p>注销账号后，您将无法进行登录哦</p>
                    <n-popconfirm @positive-click="cancelUser">
                        <template #trigger>
                            <n-button>注销账号</n-button>
                        </template>
                        你确定要注销账号吗
                    </n-popconfirm>
                </n-tab-pane>
            </n-tabs>
        </n-card>
    </div>
</template>

<script>
import { defineComponent, reactive, onMounted, h } from "vue";
// import dayjs from 'dayjs'
import { useRouter } from "vue-router";
import { NInput, NButton, NCard, NAlert, useMessage, NTabs, NTabPane, NAvatar, NImage, NUpload, NTable,NGrid,NGi,NDataTable, NPopconfirm } from 'naive-ui'
import axios from "axios";
import { debounce } from "@/utils/utils"
import UpdateAvatar from "@/components/UpdateAvatar.vue";
export default defineComponent({
  name: 'Login',
  components: { NInput, NButton, NCard, NAlert, NTabs, NTabPane, NAvatar, NImage, NUpload, NTable, NGrid,NGi,NDataTable,NPopconfirm,UpdateAvatar},
  setup() {
    const router = useRouter()
    const message = useMessage()
    const user = reactive({
      user_name: '',
      password: '',
      rePassword: '',
      file: 'https://image-flhk.flhk.net/yuyue_miniapp/avatar/default.png',
      nickName: '',
      tel: '',
      email: '',
      desc: '',
    })
    const updateUserInfo = debounce(
        async(row) => {
        try{
                const { data}  = await axios.put(`users/info/${row.id}`,{
                    nickName: row.nickName,
                    tel: row.tel,
                    email: row.email,
                    detail: row.detail,
                    avatar: row.avatar
                })
                message.success(data.message)
              }catch(err){
                message.error(data.message)
              }
    }
    )
    const paginationReactive = reactive({
      page: 1,
      pageSize: 5,
      showSizePicker: true,
      pageSizes: [ 3, 5, 7],
      onChange: (page) => {
        paginationReactive.page = page
      },
      onUpdatePageSize: (pageSize) => {
        paginationReactive.pageSize = pageSize
        paginationReactive.page = 1
      }
    })
    const columns = [
    {
      title: 'nickName',
      key: 'nickName',
      render(row, index) {
          return h(NInput, {
            value: row.nickName,
             onUpdateValue: (v)=>{
                console.log('v',v)
                console.log('index',index)
                 createData[(paginationReactive.page-1) * paginationReactive.pageSize + index].nickName = v;
                 updateUserInfo(row)
             }
          });
      }
    },
    {
      title: 'tel',
      key: 'tel',
      render(row, index) {
          return h(NInput, {
            value: row.tel,
             onUpdateValue: (v)=>{
                 createData[(paginationReactive.page-1) * paginationReactive.pageSize + index].tel = v;
                       updateUserInfo(row)
             }
          });
      }
    },
    {
      title: 'email',
      key: 'email',
      render(row, index) {
          return h(NInput, {
            value: row.email,
             onUpdateValue:async(v)=>{
                 createData[(paginationReactive.page-1) * paginationReactive.pageSize + index].email = v;
                 updateUserInfo(row)
             }
          });
      }
    },
    {
      title: 'detail',
      key: 'detail',
      render(row, index) {
          return h(NInput, {
            value: row.detail,
             onUpdateValue:async(v)=>{
                 createData[(paginationReactive.page-1) * paginationReactive.pageSize + index].detail = v;
                 updateUserInfo(row)
             }
          });
      }
    },
    {
      title: 'avatar',
      key: 'avatar',
      render(row, index) {
          return h(UpdateAvatar,{
            avatarImageUrl: row.avatar,
            onUpdateAvatar: (newAvatarUrl) => {
                console.log('~~~~~~~')
                row.avatar = newAvatarUrl
                updateUserInfo(row)
            }
             
          });
      }
    },
    {
      title: 'createdTime',
      key: 'createdAt'
    }
    ]
    const userInfoList = reactive([])
    const createData = reactive([])
    onMounted(async () => {
      await getUserInfoList()
      window.$message = message
    })

    //上传图片

    const upload = async ({ file, onError }) => {
        console.log('~~~~')
        const formData = new FormData()
        formData.append('image', file.file)
        // console.log(typeof file,file.file);
        try{
            var { data } = await axios.post('users/upload',formData)
            message.success(data.message)
        }catch(err){
            console.error(err)
            message.error(err.response.data.message)
            return 
        }
        user.file = data.result.avatar

    }
    //修改密码
    const changePassword = async() => {
        console.log('点击了按钮')
        try{
            if (!user.password) {
                message.warning('用户名或密码为空')
                return
    }
            if (user.password != user.rePassword) {
                    message.warning('两次密码不一致')
                    return
                }
            const { data } = await axios.patch('users/', {
                    user_name: user.user_name,
                    password: user.password
                })
                message.success(data.message)
        }catch(err){
            message.error(err.response.data.message)
        }
    }

    //添加用户信息
    const inputUserInfo = async() => {
        console.log('点击了按钮')
        try{
            if (!user.nickName) {
                message.warning('昵称不能为空哦！')
                return
            }
            if (!user.tel) {
                message.warning('电话不能为空哦！')
                return
            }
            const { data } = await axios.post('users/info',{
                nickName: user.nickName,
                tel: user.tel,
                email: user.email,
                detail: user.desc,
                avatar: user.file
            })
            message.success(data.message)
            getUserInfoList()
        }catch(err){
            message.error(err.response.data.message)
        }
    }
    //注销账户
    const cancelUser = async(id) => {
        
        message.success("注销账号成功");
        const { data } = await axios.post(`users/${id}/off`)
        console.log('data',data)
        router.replace('/login')
    }
    //获取用户信息
    const getUserInfoList = async() => {
        const { data } = await axios.get('users/info',{
            params: {
          query: '',
          pagenum: 1,
          pagesize: 100,
        },
        })
        userInfoList.push(...data.result.list)
        createData.push(...data.result.list)
    }

    //退出
    const exit = () => {
      sessionStorage.clear()
      router.replace('/login')
    }
    return { user, upload, changePassword, inputUserInfo,columns, userInfoList, cancelUser, exit ,createData,  pagination: paginationReactive}
  }
})
</script>

<style scoped>
.format-password {
    margin-top: 20px;
}

.password-container {
    margin-top: 20px;
}

.button {
    margin-top: 20px;
}

.mt-20 {
    margin-top: 20px;
}

.mr-10 {
    margin-left: 1450px;
    display: flex;
}

.light-green {
    height: 58px;
    width: 300px;
    background-color: rgba(0, 128, 0, 0.12);
    padding-right: 10px;
}

.green {
    height: 58px;
    width: 300px;
    background-color: rgba(0, 128, 0, 0.24);
    padding-left: 10px;
}
</style>