import { createRouter, createWebHashHistory } from 'vue-router'


//引入页面
import Login from './views/login.vue'
import Home from './views/home.vue'
import Register from './views/register.vue'


const routes = [
  { path: '/login', component: Login },
    { path: '/', component: Home },
    { path: '/register', component: Register },
  ]

const router = createRouter({
    history: createWebHashHistory(),
    routes,
})
//路由守卫
router.beforeEach((to,from,next) => {
  if(to.fullPath == '/login' || to.fullPath == '/register'){
    next()
    return 
  }
  if(!isLogin()){
    next('/login')
  }else{
    next()
  }
})

const isLogin = () => {
  return sessionStorage.getItem('token')
}

export default router