import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
//设置一个baseUrl
// axios.defaults.baseURL = 'https://localhost:3077'
//线上地址
axios.defaults.baseURL = 'https://login.api.flhk.net'


// 添加请求拦截器
axios.interceptors.request.use(function (config) {
    if (sessionStorage.getItem('token')) {
        // 在发送请求之前做些什么
        config.headers.authorization = sessionStorage.getItem('token')
    }
    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});

export {
    axios,
    VueAxios
}