<script setup>
    import { ref } from 'vue'
    import { NUpload, NImage, NIcon } from 'naive-ui'
    import { PreviewFilled, CloudUploadFilled } from '@vicons/material'
    import { uploadAvatarImage } from '../utils/utils.js'
    
    const props = defineProps({
        // 默认的图片
        avatarImageUrl: {
            type: String,
            required: false,
            default: ''
        }
    })
    const emit = defineEmits(['updateAvatar'])
    
    // previewImageUrl.value = props.avatarImageUrl || ''
    console.log('previewImageUrl',props.avatarImageUrl)
    const uploadAvatar = async ({
        file,
    }) => {

        const data = await uploadAvatarImage({file})
        // TODO 上传图片完成后，接收到新的图片路径
        emit('updateAvatar', data.result.avatar)
    }
    
    const isShow = ref(false)
    const toggleShow = () => {
        isShow.value = !isShow.value
    }
    </script>
        
    <template>
        <div class="wrapper" @mouseover="toggleShow()" @mouseout="toggleShow()">
            <n-image :src="props.avatarImageUrl" preview-disabled class="back-image" ></n-image>
    
            <div class="hover-show" v-show="isShow">
                <div class="mask"></div>
    
                <div class="edit">
                    <div class="preview">
                        <n-image :src="props.avatarImageUrl" class="click-enlarge-image"></n-image>
                        <n-icon size="20">
                            <PreviewFilled></PreviewFilled>
                        </n-icon>
                    </div>
                    <div class="upload">
                        <n-upload :custom-request="uploadAvatar" :show-file-list="false">
                            <n-icon size="20">
                                <CloudUploadFilled></CloudUploadFilled>
                            </n-icon>
                        </n-upload>
                    </div>
                </div>
            </div>
        </div>
    </template>
        
    <style scoped lang='scss'>
    .wrapper {
        position: relative;
        width: 60px;
        height: 60px;
        // border: 1px solid #000;
        border-radius: 10px;
        overflow: hidden;
    
        .back-image {
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 0;
            overflow: hidden;
            :deep(img) {
                width: 100%;
                height: 100%;
            }
        }
    
        .hover-show {
            .mask {
                width: 100%;
                height: 100%;
                position: absolute;
                z-index: 1;
                background-color: rgba($color: #fff, $alpha: 0.3);
            }
    
            .edit {
                position: absolute;
                z-index: 2;
                width: 100%;
                height: 100%;
                justify-content: center;
                align-items: center;
                display: flex;
    
                $iconWidth: 20px;
    
                .preview {
                    width: $iconWidth;
                    height: $iconWidth;
                    overflow: hidden;
    
                    .click-enlarge-image {
                        width: $iconWidth;
                        height: $iconWidth;
                        position: absolute;
                        z-index: 3;
                        opacity: 0;
                        // 图片隐藏，但是层级要高于 ICON
                    }
    
                    .n-icon {
                        position: absolute;
                        z-index: 2;
                    }
    
                }
    
                .upload {
                    width: $iconWidth;
                    height: $iconWidth;
                    margin-left: 5px;
                    cursor: pointer;
                    .n-upload{
                        position: absolute;
                        z-index: 4;

                    }
                }
    
            }
        }
    }
    </style>