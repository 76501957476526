<template>
  <div id="building">
    <div class="login-container">
      <n-card hoverable>
        <div class="login">
          <h3>登录</h3>
          <div class="input-container">
            <n-input v-model:value="user.user_name" type="text" placeholder="请输入用户名"   autocomplete="off"/>
          </div>
          <div class="password-container">
            <n-input v-model:value="user.password" type="password" show-password-on="mousedown" placeholder="请输入密码"
              :maxlength="8"  autocomplete="off"/>
          </div>
          <div class="button">
            <n-button tertiary type="primary" @click="login">登录</n-button>
            <n-button class="register-button" tertiary type="primary">
              <router-link to="/register">注册</router-link>
            </n-button>
          </div>
        </div>
      </n-card>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive } from "vue";
import { NInput, NButton, NCard, NAlert, useMessage } from 'naive-ui'
import { useRouter } from "vue-router";
import axios from "axios";
export default defineComponent({
  name: 'Login',
  components: { NInput, NButton, NCard, NAlert },
  setup() {
    const message = useMessage()
    const router = useRouter()
    const user = reactive({
      user_name: '',
      password: '',
      rePassword: ''
    })
    const login = async () => {
      console.log('点击了按钮')
      try {
        var { data } = await axios.post('users/login', {
          user_name: user.user_name,
          password: user.password
        })
        message.success(data.message)
      } catch (err) {
        message.error(err.response.data.message)
        return
      }
      sessionStorage.setItem('token',data.result.token)
        router.push('/')
    }
    return { user, login }
  }
  
})
</script>

<style scoped>
#building {
  background: url("./../assets/login.jpg");
  width: 100%;
  height: 100%;
  position: fixed;
  background-size: 100% 100%;
}

.login-container {
  width: 1600px;
  height: 800px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.n-card {
  max-width: 400px;
}

.password-container {
  margin-top: 40px;
}

.button {
  display: flex;
  justify-content: space-around;
  margin-top: 40px;
}

a {
  text-decoration: none;
}

/* .login-button {
  margin-top: 20px;
} */
</style>
    