<template>
    <div id="building">
        <div class="login-container">
            <n-card hoverable>
                <div class="login">
                    <h3>注册</h3>
                    <div class="input-container">
                        <n-input v-model:value="user.user_name" type="text" placeholder="请输入用户名" />
                    </div>
                    <div class="password-container">
                        <n-input v-model:value="user.password" type="password" show-password-on="mousedown"
                            placeholder="请输入密码" :maxlength="8" />
                    </div>
                    <div class="password-container">
                        <n-input v-model:value="user.rePassword" type="password" show-password-on="mousedown"
                            placeholder="再次请输入密码" :maxlength="8" />
                    </div>
                    <div class="button">
                        <n-button tertiary type="primary" @click="register">注册</n-button>
                        <n-button class="register-button" tertiary type="primary">
                            <router-link to="/login">返回登录界面</router-link>
                        </n-button>
                    </div>
                </div>
            </n-card>
        </div>
        <n-alert title="Warning 类型" type="warning">
            Honey disconnect the phone
        </n-alert>
    </div>
</template>
  
  <script>
import { defineComponent, reactive } from "vue";
import { NInput, NButton, NCard, NAlert, useMessage } from 'naive-ui'
import { useRouter } from "vue-router";
import axios from "axios";
export default defineComponent({
    name: 'Register',
    components: { NInput, NButton, NCard, NAlert },
    setup() {
        const message = useMessage()
        const router = useRouter()
        const user = reactive({
            user_name: '',
            password: '',
            rePassword: ''
        })
        const register = async () => {
            console.log('点击了按钮')
            try {
                if (user.password != user.rePassword) {
                    message.warning('两次密码不一致')
                    return
                }
                const {data} = await axios.post('users/register', {
                    user_name: user.user_name,
                    password: user.password
                })
                console.log('data',data)
                message.success(data.msg)
            } catch (err) { 
                message.error(err.response.data.message)
                return
            }
            router.push('/login')
        }
        return { user, register }
    }
})
</script>
  
  <style scoped>
  #building {
      background: url("./../assets/login.jpg");
      width: 100%;
      height: 100%;
      position: fixed;
      background-size: 100% 100%;
  }
  
  .login-container {
      width: 1600px;
      height: 800px;
      display: flex;
      justify-content: center;
      align-items: center;
  }
  
  .n-card {
      max-width: 400px;
  }
  
  .password-container {
      margin-top: 40px;
  }
  
  .button {
      display: flex;
      justify-content: space-around;
      margin-top: 40px;
  }
  
  a {
      text-decoration: none;
  }
  
  /* .login-button {
              margin-top: 20px;
            } */
  </style>
      